import React from "react"

import Container from "@ecom/ui/components/Container"
import { StaticImage } from "gatsby-plugin-image"

import clsx from "clsx"
import * as styles from "./comparisonCreditTable.module.scss"
import { Cansel } from "./img/Cansel"
import { Success } from "./img/Success"

type Props = {
  orderNum?: string
}

export default function ComparisonCreditTable({ orderNum }: Props) {
  return (
    <>
      <Container>
        <h2 className={styles.headTitle}>Почему «Халва» лучше</h2>
      </Container>
      <section className={styles.section} data-exclude={orderNum}>
        <Container className={styles.container}>
          <div className={styles.background}>
            <div className={styles.secondContainer}>
              <StaticImage
                className={clsx(styles.img, styles.creditCardImg)}
                alt="creditCard"
                src="./img/credit.png"
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                layout="constrained"
              />
              <StaticImage
                className={clsx(styles.img, styles.halvaImg)}
                alt="halva"
                src="./img/halva1.png"
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                layout="constrained"
              />
              <table className={styles.table}>
                <thead>
                  <tr>
                    <td />
                    <td className={styles.tableTitle}>
                      Кредитная <br /> карта
                    </td>
                    <td className={styles.tableTitle}>
                      Карта <br /> рассрочки
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr className={clsx(styles.tableRow, styles.tableRow1)}>
                    <td className={styles.tableSubtitle}>
                      Льготный <br className="d-md-none" /> период
                    </td>
                    <td className={styles.tableText}>3 месяца</td>
                    <td className={styles.tableText}>10 месяцев</td>
                  </tr>
                  <tr className={clsx(styles.tableRow, styles.tableRow2)}>
                    <td className={styles.tableSubtitle}>
                      Процент <br className="d-md-none" /> на остаток
                    </td>
                    <td>
                      <Cansel />
                    </td>
                    <td className={styles.tableText}>До 17%</td>
                  </tr>
                  <tr className={clsx(styles.tableRow, styles.tableRow3)}>
                    <td className={styles.tableSubtitle}>Кэшбэк</td>
                    <td className={styles.tableText}>3%</td>
                    <td className={styles.tableText}>До 10%</td>
                  </tr>
                  <tr className={clsx(styles.tableRow, styles.tableRow4)}>
                    <td className={styles.tableSubtitle}>
                      Процентная <br /> ставка
                    </td>
                    <td className={styles.tableText}>От 12%</td>
                    <td className={styles.tableText}> 0% </td>
                  </tr>
                  <tr className={clsx(styles.tableRow, styles.tableRow5)}>
                    <td className={styles.tableSubtitle}>
                      Снятие <br /> наличных <br />в рассрочку
                    </td>
                    <td>
                      <Cansel />
                    </td>
                    <td>
                      <Success />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}
