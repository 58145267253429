import React from "react"

export const Success = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0C8.0222 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761211 6.17317C0.00433075 8.0004 -0.193699 10.0111 0.192151 11.9509C0.578001 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.9889 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.6868 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.7612C12.6136 0.25866 11.3132 0 10 0ZM10 18.5C8.3189 18.5 6.67548 18.0015 5.27766 17.0675C3.87984 16.1335 2.79037 14.806 2.14703 13.2528C1.50368 11.6996 1.33536 9.9906 1.66333 8.3417C1.9913 6.69289 2.80085 5.17834 3.9896 3.98959C5.17834 2.80085 6.6929 1.9913 8.3417 1.66333C9.9906 1.33535 11.6996 1.50368 13.2528 2.14702C14.806 2.79037 16.1335 3.87984 17.0675 5.27765C18.0015 6.67547 18.5 8.3189 18.5 10C18.4974 12.2535 17.601 14.414 16.0075 16.0075C14.414 17.601 12.2535 18.4974 10 18.5Z"
      fill="#7DB934"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8477 7.22767C14.0715 7.50475 14.0456 7.92591 13.7898 8.16836L8.89209 12.8101C8.75295 12.9575 8.56529 13.0191 8.38559 12.9949C8.24347 12.976 8.10689 12.9037 8.00379 12.7799L6.18027 10.8048C5.93991 10.5444 5.93991 10.1223 6.18027 9.862C6.42063 9.60166 6.81033 9.60166 7.05069 9.862L8.4893 11.4202L12.9792 7.16495C13.235 6.92251 13.6238 6.95058 13.8477 7.22767Z"
      fill="#7DB934"
    />
  </svg>
)
