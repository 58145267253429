// extracted by mini-css-extract-plugin
export var background = "comparisonCreditTable-module--background--75H1X";
export var creditCardImg = "comparisonCreditTable-module--creditCardImg--qQyho";
export var halvaImg = "comparisonCreditTable-module--halvaImg--niVOP";
export var headTitle = "comparisonCreditTable-module--headTitle--DopIy";
export var img = "comparisonCreditTable-module--img--qDnY+";
export var secondContainer = "comparisonCreditTable-module--secondContainer--qCIIV";
export var section = "comparisonCreditTable-module--section--NFHqM";
export var table = "comparisonCreditTable-module--table--7sPIh";
export var tableRow = "comparisonCreditTable-module--tableRow--EHSNg";
export var tableSubtitle = "comparisonCreditTable-module--tableSubtitle--6+m3q";
export var tableText = "comparisonCreditTable-module--tableText--47wJM";
export var tableTitle = "comparisonCreditTable-module--tableTitle--Acp3m";